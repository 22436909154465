/** Build with repleek */
// #region Import
import React from "react";

import { Box, BoxProps, Container, ContainerProps } from "@repleek/mui";
// #endregion

// #region Type Definition
type LayoutWithSidebarProps = Partial<ContainerProps> & {
  tabMenu: Partial<BoxProps>,
  tabContent: Partial<BoxProps>,
};
// #endregion

// #region LayoutWithSidebarComponent
const LayoutWithSidebar: React.FC<LayoutWithSidebarProps> = (props) => {
  const { tabMenu, tabContent, ...rootProps } = props;

  return (
    <Container
      maxWidth={"md"}
      disableGutters={false}
      fixed={false}
      css={{ marginBottom: "8px", paddingTop: "16px" }}
      {...rootProps}
    >
      <Box
        tag={"div"}
        className={"bg-white box-wrapper"}
        css={{
          minHeight: "calc(100vh - 328px)",
          maxHeight: "calc(100vh - 328px)",
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
          borderRadius: "22px",
          display: "flex",
        }}
      >
        <Box
          tag={"div"}
          css={{
            padding: "16px",
            minWidth: "180px",
            maxWidth: "220px",
            borderRightWidth: "1px",
            borderRightStyle: "solid",
            borderRightColor: "rgba(126, 124, 124, 0.39)",
          }}
          {...tabMenu}
        />
        <Box
          tag={"div"}
          css={{ padding: "16px", overflowY: "auto", flexGrow: "1" }}
          {...tabContent}
        />
      </Box>
    </Container>
  );
};

export default LayoutWithSidebar;
// #endregion
