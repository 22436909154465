/** Build with repleek */
// #region Import
import React from "react";

import {
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Fragment,
  FragmentProps,
  Icon,
  Typography,
  TypographyProps,
} from "@repleek/mui";
import { PiMagicWand } from "react-icons/pi";
import { GoPaperAirplane, GoTrash } from "react-icons/go";
import { FaRegPenToSquare } from "react-icons/fa6";
// #endregion

// #region Type Definition
type ReviewProps = Partial<BoxProps> & {
  replyActions: Partial<BoxProps>,
  name: TypographyProps & {},
  date: TypographyProps & {},
  logo: Partial<AvatarProps>,
  replyBtn: ButtonProps & {},
  generateBtn: ButtonProps & {},
  stars: Partial<FragmentProps>,
  body: Partial<BoxProps>,
  replyContainer: Partial<BoxProps>,
  accountLogo: Partial<AvatarProps>,
  accountName: TypographyProps & {},
  replyContent: Partial<BoxProps>,
  replyDate: TypographyProps & {},
  editBtn: Partial<ButtonProps>,
  deleteBtn: Partial<ButtonProps>,
};
// #endregion

// #region ReviewComponent
const Review: React.FC<ReviewProps> = (props) => {
  const {
    replyActions,
    name,
    date,
    logo,
    replyBtn,
    generateBtn,
    stars,
    body,
    replyContainer,
    accountLogo,
    accountName,
    replyContent,
    replyDate,
    editBtn,
    deleteBtn,
    ...rootProps
  } = props;

  return (
    <Box
      tag={"div"}
      css={{
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingTop: "32px",
        paddingBottom: "32px",
      }}
      {...rootProps}
    >
      <Box
        tag={"div"}
        css={{ gap: "16px", display: "flex", alignItems: "center" }}
      >
        <Avatar
          variant={"circular"}
          component={"div"}
          css={{ width: "64px", height: "64px" }}
          {...logo}
        />
        <Box tag={"div"} css={{ flexGrow: "1" }}>
          <Typography
            variant={"h4"}
            text={"Mon titre ici"}
            gutterBottom={false}
            noWrap={false}
            css={{ fontSize: "20px" }}
            {...name}
          />
          <Box
            tag={"div"}
            css={{
              gap: "8px",
              display: "flex",
              alignItems: "center",
              svg: { color: "rgb(249, 125, 241)", fontSize: "18px" },
            }}
          >
            <Typography
              variant={"body1"}
              text={"Mon titre ici"}
              gutterBottom={false}
              noWrap={false}
              css={{ fontSize: "18px" }}
              {...date}
            />
            <Fragment {...stars} />
          </Box>
        </Box>
      </Box>
      <Box tag={"div"} css={{ paddingLeft: "80px" }}>
        <Box
          tag={"div"}
          css={{
            padding: "16px",
            backgroundColor: "rgba(171, 171, 177, 0.12)",
            borderRadius: "8px",
            marginTop: "8px",
            marginBottom: "8px",
          }}
          {...body}
        />
        <Box
          tag={"div"}
          css={{ marginTop: "16px", marginBottom: "16px" }}
          {...replyContainer}
        >
          <Box
            tag={"div"}
            css={{ gap: "14px", display: "flex", alignItems: "center" }}
          >
            <Avatar
              variant={"circular"}
              component={"div"}
              css={{ width: "48px", height: "48px" }}
              {...accountLogo}
            />
            <Box tag={"div"} css={{ flexGrow: "1" }}>
              <Typography
                variant={"h4"}
                text={"Mon titre ici"}
                gutterBottom={false}
                noWrap={false}
                css={{ fontSize: "16px" }}
                {...accountName}
              />
              <Box
                tag={"div"}
                css={{ gap: "8px", display: "flex", alignItems: "center" }}
              >
                <Typography
                  variant={"body1"}
                  text={"Propriétaire"}
                  gutterBottom={false}
                  noWrap={false}
                  css={{ fontSize: "14px" }}
                />
                <Typography
                  variant={"body1"}
                  text={"Mon titre ici"}
                  gutterBottom={false}
                  noWrap={false}
                  css={{ fontSize: "18px" }}
                  {...replyDate}
                />
              </Box>
            </Box>
          </Box>
          <Box
            tag={"div"}
            css={{ marginTop: "8px", gap: "8px", display: "flex" }}
          >
            <Box
              tag={"div"}
              css={{
                backgroundColor: "rgba(171, 171, 177, 0.12)",
                borderRadius: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
                paddingBottom: "8px",
                flexGrow: "1",
              }}
              {...replyContent}
            />
            <Box
              tag={"div"}
              css={{ gap: "8px", display: "flex", flexDirection: "column" }}
            >
              <Button
                type={"button"}
                disabled={false}
                disableElevation={false}
                disableFocusRipple={false}
                disableRipple={false}
                fullWidth={false}
                variant={"contained"}
                iconStart={true}
                iconEnd={false}
                className={"icon-btn"}
                IconStart={
                  <Icon
                    color={"inherit"}
                    fontSize={"inherit"}
                    icon={FaRegPenToSquare}
                  />
                }
                {...editBtn}
              />
              <Button
                type={"button"}
                disabled={false}
                disableElevation={false}
                disableFocusRipple={false}
                disableRipple={false}
                fullWidth={false}
                variant={"contained"}
                color={"error"}
                iconStart={true}
                iconEnd={false}
                className={"icon-btn"}
                IconStart={
                  <Icon color={"inherit"} fontSize={"inherit"} icon={GoTrash} />
                }
                {...deleteBtn}
              />
            </Box>
          </Box>
        </Box>
        <Box tag={"div"} {...replyActions}>
          <Button
            text={"Repondre"}
            type={"button"}
            disabled={false}
            disableElevation={false}
            disableFocusRipple={false}
            disableRipple={false}
            fullWidth={false}
            variant={"contained"}
            iconStart={false}
            iconEnd={true}
            css={{ marginRight: "8px" }}
            IconEnd={
              <Icon
                color={"inherit"}
                fontSize={"inherit"}
                icon={GoPaperAirplane}
              />
            }
            {...replyBtn}
          />
          <Button
            text={"Générez une reponse"}
            type={"button"}
            disabled={false}
            disableElevation={false}
            disableFocusRipple={false}
            disableRipple={false}
            fullWidth={false}
            size={"large"}
            iconStart={false}
            iconEnd={true}
            css={{
              color: "rgba(255,255,255, 1)",
              background:
                "linear-gradient(90deg, rgb(151, 150, 240) 0%, rgb(251, 199, 212) 100%)",
            }}
            IconEnd={
              <Icon color={"inherit"} fontSize={"inherit"} icon={PiMagicWand} />
            }
            {...generateBtn}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Review;
// #endregion
