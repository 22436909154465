import { useEffect, useMemo } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { LayoutDefinition } from "@/interfaces";
import { User } from "@/interfaces/strapi/collections";
import { accountSelected } from "@/store/auth";
import { layoutState, MenuItem, SubMenu, subMenuState } from "@/store/layout";
import { useColorMode } from "@repleek/mui";

import {
  LuLayoutDashboard,
  LuUsers,
  LuStore,
  LuCalendarRange,
  LuMapPin,
  LuFolderOpen,
  LuRocket,
  LuCode,
  LuMessagesSquare,
} from "react-icons/lu";
import { StrapiUser } from "@/modules/strapi-sdk";

export const useBreadCrumb = (breadcrumbs: LayoutDefinition["breadcrumbs"]) => {
  const [layout, setLayout] = useRecoilState(layoutState);

  useEffect(() => {
    setLayout({ ...layout, breadcrumbs });
    return () => {
      setLayout({ ...layout, breadcrumbs: [] });
    };
  }, []);
};

export const useThemeChange = () => {
  const { mode } = useColorMode();

  useEffect(() => {
    const body = document.querySelector("body");
    if (mode === "dark") body?.classList.add("mui-dark");
    else body?.classList.remove("mui-dark");
  }, [mode]);
};

export const useMenu = (type: User["type"]) => {
  const myAccount = useRecoilValue(accountSelected);
  const subMenu = useRecoilValue(subMenuState);

  return useMemo<MenuItem[]>(() => {
    let menu: MenuItem[] = [];

    switch (type) {
      case "admin":
      case "cm":
        menu = navigations["admin"];
        // account navigation submenu
        if (subMenu?.accounts)
          menu = menu.map((item) =>
            item.href === "/accounts"
              ? { ...item, active: true, children: subMenu.accounts }
              : item
          );
        // workspaces navigation submenu
        if (subMenu?.workspaces)
          menu = menu.map((item) =>
            item.href === "/workspaces"
              ? { ...item, children: subMenu.workspaces }
              : item
          );
        break;

      case "customer":
      case "commercial":
        menu = navigations["customer"];
        if (myAccount?.sheet?.id) {
          menu = [
            ...menu,
            {
              label: "Leads",
              href: "/leads",
              icon: LuUsers,
            },
          ];
        }

        if (myAccount?.gmb) {
          menu = [
            ...menu,
            {
              label: "Votre Fiche",
              href: "/gmb",
              icon: LuMapPin,
            },
            {
              label: "Vos Avis",
              href: "/gmb/reviews",
              icon: LuMessagesSquare,
            },
          ];
        }
        break;

      case "developper":
        menu = navigations["developper"];
        break;

      case "ad_manager":
        menu = navigations["ad_manager"];
        break;

      default:
        menu = navigations["default"];
        break;
    }

    return menu;
  }, [
    myAccount?.gmb,
    myAccount?.sheet?.id,
    subMenu.accounts,
    subMenu.workspaces,
    type,
  ]);
};

export const useSetSubMenu = (type: SubMenu, menu: MenuItem[]) => {
  const setMenu = useSetRecoilState(subMenuState);

  useEffect(() => {
    setMenu({ [type]: menu });
    return () => {
      setMenu({});
    };
  }, [type, menu, setMenu]);
};

export const useSelectAccount = (user?: StrapiUser) => {
  return useMemo(() => {
    const rules: Record<User["type"], boolean> = {
      customer: true,
      ad_manager: true,
      commercial: true,
      admin: false,
      developper: false,
      cm: false,
    };
    return user?.type && rules[user.type];
  }, [user?.type]);
};

export const navigations = {
  admin: [
    {
      label: "Dashboard",
      href: "/",
      icon: LuLayoutDashboard,
    },
    {
      label: "Planning",
      href: "/calendar",
      icon: LuCalendarRange,
    },
    {
      label: "Comptes",
      href: "/accounts",
      icon: LuFolderOpen,
    },
    {
      label: "Utilisateurs",
      href: "/users",
      icon: LuUsers,
    },
    {
      label: "Workspaces",
      href: "/workspaces",
      icon: LuRocket,
    },
  ],
  customer: [
    {
      label: "Accueil",
      href: "/",
      icon: LuStore,
    },
    {
      label: "Planning",
      href: "/planning",
      icon: LuCalendarRange,
    },
  ],
  developper: [
    {
      label: "Accueil",
      href: "/",
      icon: LuStore,
    },
    {
      label: "Documentation",
      href: "/documentation",
      icon: LuCode,
    },
  ],
  ad_manager: [
    {
      label: "Accueil",
      href: "/",
      icon: LuStore,
    },
    {
      label: "Planning",
      href: "/planning",
      icon: LuCalendarRange,
    },
  ],
  default: [],
};
