import React, { Suspense } from "react";
import { RepleekProvider } from "@repleek/mui";
import pages from "@/routes.config";
import themes from "@/theme";
import i18n from "@/locales/i18n";
import { Route, Routes, useNavigate } from "react-router-dom";
import { StrapiProvider } from "./providers/StrapiProvider";
import { RecoilRoot } from "recoil";
import { ToastContainer } from "react-toastify";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import "./styles/global.css";
import "react-toastify/dist/ReactToastify.css";
import AppProvider from "./providers/AppProvider";
import { PistacheLoader } from "./components/layout";
import AppLayout from "./app/layout";
import RecoilNexus from "recoil-nexus";

const App: React.FC = () => {
  const push = useNavigate();

  return (
    <RecoilRoot>
      <RecoilNexus />
      <StrapiProvider>
        <RepleekProvider
          push={push}
          themes={themes}
          i18n={i18n}
          dateAdapter={AdapterDayjs}
        >
          <AppProvider>
            <Routes>
              {pages.map(
                ({
                  path,
                  page: Page,
                  layout: CustomLayout,
                  ...layoutProps
                }) => (
                  <Route element={<AppLayout {...layoutProps} />} key={path}>
                    <Route
                      element={
                        <Suspense fallback={<PistacheLoader />}>
                          <Page />
                        </Suspense>
                      }
                      path={path}
                    />
                  </Route>
                )
              )}
            </Routes>
          </AppProvider>
          <ToastContainer />
        </RepleekProvider>
      </StrapiProvider>
    </RecoilRoot>
  );
};

export default App;
