/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Icon,
  Image,
  Typography,
  TypographyProps,
} from "@repleek/mui";
import { RiLogoutCircleRLine } from "react-icons/ri";
// #endregion

// #region Type Definition
type GmbBasicFieldsProps = Partial<BoxProps> & {
  name: TypographyProps & {},
  change: ButtonProps & {},
  unlink: Partial<ButtonProps>,
  generateReviewsReply: Partial<BoxProps>,
  syncReviews: ButtonProps & {},
};
// #endregion

// #region GmbBasicFieldsComponent
const GmbBasicFields: React.FC<GmbBasicFieldsProps> = (props) => {
  const {
    name,
    change,
    unlink,
    generateReviewsReply,
    syncReviews,
    ...rootProps
  } = props;

  return (
    <Box tag={"div"} {...rootProps}>
      <Box
        tag={"div"}
        css={{
          gap: "8px",
          marginTop: "16px",
          marginBottom: "16px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Image
          alt={"Image description for better SEO"}
          src={"/images/logo-google.svg"}
          dynamic={true}
          css={{ height: "48px" }}
        />
        <Typography
          variant={"h3"}
          text={"Mon titre ici"}
          gutterBottom={false}
          noWrap={false}
          css={{ fontSize: "16px", flexGrow: "1" }}
          {...name}
        />
        <Button
          text={"Changer"}
          type={"button"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          variant={"outlined"}
          iconStart={false}
          iconEnd={false}
          {...change}
        />
        <Button
          type={"button"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          variant={"contained"}
          iconStart={true}
          iconEnd={false}
          className={"icon-btn"}
          IconStart={
            <Icon
              color={"inherit"}
              fontSize={"inherit"}
              icon={RiLogoutCircleRLine}
            />
          }
          {...unlink}
        />
      </Box>
      <Box
        tag={"div"}
        css={{ gap: "8px", marginBottom: "16px", display: "flex" }}
      >
        <Box tag={"div"} css={{ flexGrow: "1" }}>
          <Typography
            variant={"h3"}
            text={"Réponses aux avis automatiques"}
            gutterBottom={false}
            noWrap={false}
            css={{ fontSize: "16px", flexGrow: "1", marginBottom: "4px" }}
          />
          <Typography
            variant={"body1"}
            text={
              "Activez cette option pour générer les réponses aux avis à l'aide de l'IA.\n\n\n\n\n\n\n\n"
            }
            gutterBottom={false}
            noWrap={false}
            css={{ fontSize: "14px", flexGrow: "1" }}
          />
        </Box>
        <Box tag={"div"} {...generateReviewsReply} />
      </Box>
      <Box
        tag={"div"}
        css={{
          gap: "14px",
          marginBottom: "16px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box tag={"div"} css={{ flexGrow: "1" }}>
          <Typography
            variant={"h3"}
            text={"Actualiser vos avis Google"}
            gutterBottom={false}
            noWrap={false}
            css={{ fontSize: "16px", flexGrow: "1", marginBottom: "4px" }}
          />
          <Typography
            variant={"body1"}
            text={
              "Pour voir l’intégralité de vos avis, appuyez sur « Récupérer ». Vos avis manquants seront automatiquement téléchargés."
            }
            gutterBottom={false}
            noWrap={false}
            css={{ fontSize: "14px", flexGrow: "1" }}
          />
        </Box>
        <Button
          text={"Récuperer"}
          type={"button"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          size={"small"}
          variant={"outlined"}
          iconStart={false}
          iconEnd={false}
          {...syncReviews}
        />
      </Box>
    </Box>
  );
};

export default GmbBasicFields;
// #endregion
