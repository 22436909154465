/** Build with repleek */
// #region Import
import React from "react";

import {
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Checkbox,
  Chip,
  Container,
  Divider,
  Dynamic,
  Form,
  FormProps,
  Icon,
  Image,
  Paper,
  SelectField,
  SelectFieldProps,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";
import { FaReact } from "react-icons/fa";
import { FaCircleCheck } from "react-icons/fa6";
// #endregion

// #region Type Definition
type AccountFormProps = Partial<FormProps> & {
  form: Partial<FormProps>,
  name: Partial<TextFieldProps>,
  save: ButtonProps & {},
  fields: Partial<BoxProps>,
  title: TypographyProps & {},
  email: Partial<TextFieldProps>,
  back: ButtonProps & {},
  type: Partial<SelectFieldProps>,
  loader: Partial<BoxProps>,
  notionSync: ButtonProps & {},
  logo: Partial<AvatarProps>,
  modal: Partial<BoxProps>,
  basicInfo: Partial<BoxProps>,
  hootsuiteSync: Partial<BoxProps>,
  workspace: Partial<SelectFieldProps>,
  moveBtn: ButtonProps & {},
  postizSync: Partial<BoxProps>,
};
// #endregion

// #region AccountFormComponent
const AccountForm: React.FC<AccountFormProps> = (props) => {
  const {
    form,
    name,
    save,
    fields,
    title,
    email,
    back,
    type,
    loader,
    notionSync,
    logo,
    modal,
    basicInfo,
    hootsuiteSync,
    workspace,
    moveBtn,
    postizSync,
    ...rootProps
  } = props;

  return (
    <Form mode={"onSubmit"} css={{ fontSize: "12px" }} {...form} {...rootProps}>
      <Container maxWidth={"sm"} disableGutters={false} fixed={false}>
        <Paper
          square={false}
          variant={"outlined"}
          elevation={0}
          css={{ padding: "42px", borderRadius: "26px" }}
        >
          <Box
            tag={"div"}
            className={"head"}
            css={{
              marginBottom: "28px",
              gap: "16px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Avatar
              variant={"rounded"}
              component={"div"}
              css={{
                width: "64px",
                height: "64px",
                fontFamily: "Unbounded",
                fontSize: "28px",
                textTransform: "uppercase",
                background:
                  "linear-gradient(90deg, rgb(238, 156, 167) 0%, rgb(255, 221, 225) 100%)",
              }}
              {...logo}
            />
            <Typography
              variant={"h1"}
              text={"Nouveau compte"}
              gutterBottom={false}
              noWrap={false}
              css={{ fontSize: "28px", textTransform: "uppercase" }}
              {...title}
            />
          </Box>
          <Box tag={"div"} {...loader} />
          <Box tag={"div"} css={{ marginBottom: "16px" }} {...fields}>
            <Box
              tag={"div"}
              css={{
                marginBottom: "16px",
                gap: "8px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <SelectField
                options={[{ label: "Default", value: "default" }]}
                inputName={"type"}
                label={"Type de compte"}
                variant={"outlined"}
                color={"secondary"}
                aria-label={"Input Base Mui"}
                autoFocus={false}
                disabled={false}
                fullWidth={true}
                multiple={false}
                readOnly={false}
                required={true}
                size={"small"}
                {...type}
              />
              <SelectField
                options={[{ label: "Default", value: "default" }]}
                inputName={"workspace"}
                label={"Workspace"}
                variant={"outlined"}
                color={"secondary"}
                aria-label={"Input Base Mui"}
                autoFocus={false}
                disabled={false}
                fullWidth={true}
                multiple={false}
                readOnly={false}
                required={true}
                size={"small"}
                {...workspace}
              />
              <Button
                text={"deplacer"}
                type={"button"}
                disabled={false}
                disableElevation={false}
                disableFocusRipple={false}
                disableRipple={false}
                fullWidth={false}
                size={"small"}
                variant={"outlined"}
                iconStart={false}
                iconEnd={false}
                css={{
                  minWidth: "124px",
                  fontSize: "12px",
                  boxShadow: "rgba(0,0,0,1) 2px 2px   ",
                  paddingLeft: "14px",
                  paddingRight: "14px",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                  display: "block",
                }}
                {...moveBtn}
              />
            </Box>
            <Dynamic variableName={"notion_id"} operator={"<"} compareTo={"1"}>
              <Box
                tag={"div"}
                css={{ marginBottom: "16px", gap: "8px", display: "flex" }}
              >
                <TextField
                  inputName={"name"}
                  label={"Nom du compte"}
                  variant={"outlined"}
                  color={"secondary"}
                  type={"text"}
                  aria-label={"Input Base Mui"}
                  autoFocus={false}
                  disabled={false}
                  fullWidth={true}
                  maxRows={1}
                  minRows={1}
                  rows={1}
                  multiline={false}
                  readOnly={false}
                  required={true}
                  size={"small"}
                  {...name}
                />
                <TextField
                  inputName={"email"}
                  label={"E-mail"}
                  variant={"outlined"}
                  color={"secondary"}
                  type={"email"}
                  aria-label={"Input Base Mui"}
                  autoFocus={false}
                  disabled={false}
                  fullWidth={true}
                  maxRows={1}
                  minRows={1}
                  rows={1}
                  multiline={false}
                  readOnly={false}
                  required={true}
                  size={"small"}
                  {...email}
                />
              </Box>
            </Dynamic>
            <Box
              tag={"div"}
              css={{ gap: "8px", display: "flex" }}
              {...basicInfo}
            />
            <Checkbox
              inputName={"notification"}
              label={"Activer la notification par email"}
              color={"primary"}
              isChecked={false}
              disabled={false}
              disableTypography={false}
              aria-label={"Input Base Mui"}
              size={"medium"}
            />
            <Box
              tag={"div"}
              css={{
                boxShadow:
                  "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                gap: "8px",
                borderRadius: "36px",
                paddingLeft: "12px",
                paddingRight: "8px",
                paddingTop: "8px",
                paddingBottom: "8px",
                marginTop: "4px",
                marginBottom: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Image
                alt={"Image description for better SEO"}
                src={"/images/logo-notion.svg"}
                dynamic={true}
                css={{
                  padding: "4px",
                  height: "36px",
                  backgroundColor: "rgba(255,255,255, 1)",
                  borderRadius: "36px",
                }}
              />
              <Typography
                variant={"h3"}
                text={"Notion"}
                gutterBottom={false}
                noWrap={false}
                css={{ fontSize: "14px", flexGrow: "1" }}
              />
              <Button
                text={"Connecter"}
                type={"button"}
                disabled={false}
                disableElevation={false}
                disableFocusRipple={false}
                disableRipple={false}
                fullWidth={false}
                size={"small"}
                variant={"outlined"}
                iconStart={true}
                iconEnd={false}
                css={{
                  fontSize: "12px",
                  boxShadow: "rgba(0,0,0,1) 2px 2px   ",
                  paddingLeft: "14px",
                  paddingRight: "14px",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                }}
                IconStart={
                  <Icon
                    color={"inherit"}
                    fontSize={"inherit"}
                    icon={FaCircleCheck}
                  />
                }
                {...notionSync}
              />
            </Box>
            <Box
              tag={"div"}
              css={{
                boxShadow:
                  "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                gap: "8px",
                borderRadius: "36px",
                paddingLeft: "12px",
                paddingRight: "8px",
                paddingTop: "8px",
                paddingBottom: "8px",
                marginBottom: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Image
                alt={"Image description for better SEO"}
                src={"/images/logo-hootsuite.svg"}
                dynamic={true}
                css={{ height: "36px" }}
              />
              <Typography
                variant={"h3"}
                text={"Hootsuite"}
                gutterBottom={false}
                noWrap={false}
                css={{ fontSize: "14px", flexGrow: "1" }}
              />
              <Box
                tag={"div"}
                css={{ gap: "8px", display: "flex" }}
                {...hootsuiteSync}
              />
            </Box>
            <Box
              tag={"div"}
              css={{
                boxShadow:
                  "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                gap: "8px",
                borderRadius: "36px",
                paddingLeft: "12px",
                paddingRight: "8px",
                paddingTop: "8px",
                paddingBottom: "8px",
                marginBottom: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Image
                alt={"Image description for better SEO"}
                src={"/images/postiz.svg"}
                dynamic={true}
                css={{ height: "36px" }}
              />
              <Typography
                variant={"h3"}
                text={"Postiz"}
                gutterBottom={false}
                noWrap={false}
                css={{ fontSize: "14px", flexGrow: "1" }}
              />
              <Box
                tag={"div"}
                css={{ gap: "8px", display: "flex" }}
                {...postizSync}
              />
            </Box>
            <Dynamic variableName={"notion_id"} operator={"<"} compareTo={"1"}>
              <Divider
                label={"Utilisateur"}
                chip={true}
                flexItem={false}
                light={false}
                orientation={"horizontal"}
                textAlign={"left"}
                variant={"fullWidth"}
                css={{ marginTop: "12px" }}
                Chip={
                  <Chip
                    label={"Réseaux sociaux"}
                    avatar={false}
                    icon={false}
                    clickable={false}
                    color={"secondary"}
                    variant={"filled"}
                    css={{
                      fontSize: "12px",
                      color: "#FFFFFF",
                      textTransform: "uppercase",
                    }}
                    Avatar={
                      <Avatar
                        variant={"circular"}
                        component={"div"}
                        className={
                          "MuiChip-avatar MuiChip-avatarMedium MuiChip-avatarColorDefault"
                        }
                      />
                    }
                    Icon={
                      <Icon
                        color={"inherit"}
                        fontSize={"inherit"}
                        icon={FaReact}
                        className={
                          "MuiChip-icon MuiChip-iconMedium MuiChip-iconColorDefault"
                        }
                      />
                    }
                  />
                }
              />
              <Box
                tag={"div"}
                css={{
                  gridTemplateColumns: "1fr 1fr",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <Checkbox
                  inputName={"configuration.facebook"}
                  label={"Facebook"}
                  color={"primary"}
                  isChecked={false}
                  disabled={false}
                  disableTypography={false}
                  aria-label={"Input Base Mui"}
                  size={"medium"}
                  css={{ minWidth: "50%" }}
                />
                <Checkbox
                  inputName={"configuration.instagram"}
                  label={"Instagram"}
                  color={"primary"}
                  isChecked={false}
                  disabled={false}
                  disableTypography={false}
                  aria-label={"Input Base Mui"}
                  size={"medium"}
                />
                <Checkbox
                  inputName={"configuration.tiktok"}
                  label={"TikTok"}
                  color={"primary"}
                  isChecked={false}
                  disabled={false}
                  disableTypography={false}
                  aria-label={"Input Base Mui"}
                  size={"medium"}
                  css={{ minWidth: "50%" }}
                />
                <Checkbox
                  inputName={"configuration.linkedin"}
                  label={"LinkedIn"}
                  color={"primary"}
                  isChecked={false}
                  disabled={false}
                  disableTypography={false}
                  aria-label={"Input Base Mui"}
                  size={"medium"}
                />
              </Box>
            </Dynamic>
            <Box tag={"div"} {...modal} />
            <Box
              tag={"div"}
              className={"footer"}
              css={{
                marginTop: "16px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                text={"Retour"}
                type={"button"}
                disabled={false}
                disableElevation={false}
                disableFocusRipple={false}
                disableRipple={false}
                fullWidth={false}
                color={"secondary"}
                iconStart={false}
                iconEnd={false}
                {...back}
              />
              <Button
                text={"Enregistrer"}
                type={"submit"}
                disabled={false}
                disableElevation={false}
                disableFocusRipple={false}
                disableRipple={false}
                fullWidth={false}
                variant={"contained"}
                iconStart={false}
                iconEnd={false}
                {...save}
              />
            </Box>
          </Box>
        </Paper>
      </Container>
    </Form>
  );
};

export default AccountForm;
// #endregion
