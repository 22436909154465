import { AppLayoutProps } from "@/app/layout";
import { useStrapiInstance } from "@/providers/StrapiProvider";
import { authState } from "@/store/auth";
import { useNavigate } from "@repleek/mui";
import { useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";

export const useAuth = (control: AppLayoutProps["control"]) => {
  const { redirect } = control;
  const strapi = useStrapiInstance();
  const user = useRecoilValue(authState);
  const { navigate } = useNavigate();
  const [loading, setLoading] = useState(true);

  const autorized = useMemo(() => {
    switch (control.mode) {
      case "LOGGED_IN":
        const { role } = control;

        if (!user) return navigate?.(redirect);

        if (role === "*" || role.includes(user.type)) return true;

        return navigate?.(redirect);

      case "LOGGED_OUT":
        if (user) navigate?.(redirect);
        return true;

      case "PUBLIC":
        return true;
    }
  }, [control, user, navigate, redirect]);

  useEffect(() => {
    if (!user && control.mode === "LOGGED_IN") {
      setLoading(true);
      strapi
        ?.fetchUser()
        .then((res) => {
          if (res?.id) strapi.get("/auth/trace");
        })
        .finally(() => setLoading(false));
    } else setLoading(false);
  }, [control.mode, strapi, user]);

  return { autorized, loading };
};

export const useResetToken = (email?: string) => {
  const strapi = useStrapiInstance();
  const [token, setToken] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (email) {
      strapi
        ?.get("/auth/:email/reset-token", {
          ":email": email,
        })
        .then((token) => {
          if (token) setToken(token);
        });
    }
  }, [email, strapi]);

  return token;
};
