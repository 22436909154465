/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Container,
  ContainerProps,
  Divider,
  Icon,
  Image,
  Typography,
  TypographyProps,
} from "@repleek/mui";
import { IoOpenOutline } from "react-icons/io5";
import { RiRefreshLine } from "react-icons/ri";
// #endregion

// #region Type Definition
type GmbLocationProps = Partial<ContainerProps> & {
  maps: Partial<BoxProps>,
  address: TypographyProps & {
    address?: string,
  },
  phone: TypographyProps & {
    phone?: string,
  },
  hours: Partial<BoxProps>,
  starRating: TypographyProps & {},
  name: TypographyProps & {},
  description: Partial<TypographyProps>,
  stars: Partial<BoxProps>,
  websiteUri: TypographyProps & {
    websiteUri?: string,
  },
  editBtn: ButtonProps & {},
  reviewsCount: TypographyProps & {},
  resyncBtn: Partial<ButtonProps>,
};
// #endregion

// #region GmbLocationComponent
const GmbLocation: React.FC<GmbLocationProps> = (props) => {
  const {
    maps,
    address,
    phone,
    hours,
    starRating,
    name,
    description,
    stars,
    websiteUri,
    editBtn,
    reviewsCount,
    resyncBtn,
    ...rootProps
  } = props;

  return (
    <Container
      maxWidth={"md"}
      disableGutters={false}
      fixed={false}
      css={{ marginTop: "16px" }}
      {...rootProps}
    >
      <Container maxWidth={"md"} disableGutters={false} fixed={false}>
        <Box
          tag={"div"}
          css={{
            overflow: "hidden",
            borderRadius: "16px",
            marginBottom: "24px",
          }}
          {...maps}
        />
      </Container>
      <Container maxWidth={"sm"} disableGutters={false} fixed={false}>
        <Box tag={"div"}>
          <Box
            tag={"div"}
            css={{
              marginBottom: "8px",
              gap: "8px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant={"h1"}
              text={"Mon titre ici"}
              gutterBottom={false}
              noWrap={false}
              css={{ fontSize: "22px", flexGrow: "1" }}
              {...name}
            />
            <Button
              type={"button"}
              disabled={false}
              disableElevation={false}
              disableFocusRipple={false}
              disableRipple={false}
              fullWidth={false}
              size={"small"}
              variant={"outlined"}
              color={"secondary"}
              iconStart={true}
              iconEnd={false}
              className={"icon-btn"}
              IconStart={
                <Icon
                  color={"inherit"}
                  fontSize={"inherit"}
                  icon={RiRefreshLine}
                />
              }
              {...resyncBtn}
            />
            <Button
              text={"Modifier"}
              type={"button"}
              disabled={false}
              disableElevation={false}
              disableFocusRipple={false}
              disableRipple={false}
              fullWidth={false}
              size={"small"}
              variant={"contained"}
              iconStart={false}
              iconEnd={false}
              {...editBtn}
            />
          </Box>
          <Typography
            variant={"body1"}
            gutterBottom={false}
            noWrap={false}
            {...description}
          />
        </Box>
        <Divider
          chip={false}
          flexItem={false}
          light={false}
          orientation={"horizontal"}
          textAlign={"center"}
          variant={"fullWidth"}
        />
        <Box
          tag={"div"}
          css={{ gap: "8px", display: "flex", flexDirection: "column" }}
        >
          <Typography
            variant={"body1"}
            text={"<b>Adresse</b> : {{address}}"}
            gutterBottom={false}
            noWrap={false}
            {...address}
          />
          <Typography
            variant={"body1"}
            text={"<b>Téléphone</b> : {{phone}}"}
            gutterBottom={false}
            noWrap={false}
            {...phone}
          />
          <Typography
            variant={"body1"}
            text={"<b>Site web</b> : {{websiteUri}}"}
            gutterBottom={false}
            noWrap={false}
            {...websiteUri}
          />
          <Box tag={"div"} {...hours} />
          <Box
            tag={"div"}
            css={{
              padding: "16px",
              width: "250px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "rgba(126, 124, 124, 0.39)",
              borderRadius: "16px",
              cursor: "pointer",
              marginTop: "8px",
            }}
          >
            <Box
              tag={"div"}
              css={{
                gap: "16px",
                marginBottom: "16px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Image
                alt={"Image description for better SEO"}
                src={"/images/logo-google.svg"}
                dynamic={true}
                css={{ height: "28px" }}
              />
              <Typography
                variant={"body1"}
                text={"Google"}
                gutterBottom={false}
                noWrap={false}
                css={{
                  fontFamily: "Unbounded",
                  fontSize: "18px",
                  flexGrow: "1",
                }}
              />
              <Icon
                color={"inherit"}
                fontSize={"inherit"}
                icon={IoOpenOutline}
                css={{ fontSize: "24px" }}
              />
            </Box>
            <Box
              tag={"div"}
              css={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant={"body1"}
                text={"<b>5/5</b>"}
                gutterBottom={false}
                noWrap={false}
                css={{ fontFamily: "Unbounded" }}
                {...starRating}
              />
              <Box tag={"div"} {...stars} />
              <Typography
                variant={"body1"}
                text={"30 avis"}
                gutterBottom={false}
                noWrap={false}
                {...reviewsCount}
              />
            </Box>
          </Box>
        </Box>
      </Container>
    </Container>
  );
};

export default GmbLocation;
// #endregion
