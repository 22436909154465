/** Build with repleek */
// #region Import
import React from "react";

import {
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  ButtonIcon,
  ButtonIconProps,
  Icon,
  Image,
  ImageProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";
import { IoArchiveOutline } from "react-icons/io5";
import { TiInfoLarge } from "react-icons/ti";
// #endregion

// #region Type Definition
type SocialMediaCardProps = Partial<BoxProps> & {
  name: TypographyProps & {},
  deleteBtn: Partial<ButtonIconProps>,
  refreshBtn: Partial<ButtonIconProps>,
  avatar: Partial<AvatarProps>,
  social: Partial<ImageProps>,
};
// #endregion

// #region SocialMediaCardComponent
const SocialMediaCard: React.FC<SocialMediaCardProps> = (props) => {
  const { name, deleteBtn, refreshBtn, avatar, social, ...rootProps } = props;

  return (
    <Box
      tag={"div"}
      className={"bg-white"}
      css={{
        padding: "12px",
        maxWidth: "450px",
        boxShadow:
          "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px , rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        overflow: "hidden",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
      }}
      {...rootProps}
    >
      <Box
        tag={"div"}
        css={{
          paddingTop: "6px",
          marginBottom: "8px",
          gap: "12px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box tag={"div"} css={{ position: "relative" }}>
          <Avatar
            variant={"circular"}
            component={"div"}
            css={{
              width: "54px",
              height: "54px",
              fontSize: "24px",
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor: "rgb(242, 242, 242)",
              background:
                "linear-gradient(90deg, rgb(238, 156, 167) 0%, rgb(255, 221, 225) 100%)",
            }}
            {...avatar}
          />
          <Image
            alt={"Image description for better SEO"}
            src={"/icons/platforms/instagram-standalone.png"}
            dynamic={true}
            css={{
              width: "24px",
              height: "24px",
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor: "rgba(242, 242, 242, 1)",
              position: "absolute",
              bottom: "-4px",
              right: "-4px",
              borderRadius: "16px",
            }}
            {...social}
          />
        </Box>
        <Box
          tag={"div"}
          css={{ flexGrow: "1", display: "flex", flexDirection: "column" }}
        >
          <Box
            tag={"div"}
            css={{
              gap: "8px",
              marginBottom: "11px",
              display: "inline-flex",
              justifyContent: "flex-end",
            }}
          >
            <ButtonIcon
              type={"button"}
              disabled={false}
              disableFocusRipple={false}
              disableRipple={false}
              size={"small"}
              color={"primary"}
              css={{
                color: "rgba(255,255,255, 1)",
                background:
                  "linear-gradient(90deg, rgb(255, 65, 108) 0%, rgb(255, 75, 43) 100%)",
              }}
              Icon={
                <Icon
                  color={"inherit"}
                  fontSize={"inherit"}
                  icon={TiInfoLarge}
                />
              }
              {...refreshBtn}
            />
            <ButtonIcon
              type={"button"}
              disabled={false}
              disableFocusRipple={false}
              disableRipple={false}
              size={"small"}
              color={"error"}
              Icon={
                <Icon
                  color={"inherit"}
                  fontSize={"inherit"}
                  icon={IoArchiveOutline}
                />
              }
              {...deleteBtn}
            />
          </Box>
          <Typography
            variant={"h3"}
            text={"Mon titre ici"}
            gutterBottom={false}
            noWrap={false}
            css={{ fontSize: "14px", textAlign: "right" }}
            {...name}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SocialMediaCard;
// #endregion
