import { lazy } from "react";
import { AppLayoutProps } from "./app/layout";
import { navigations } from "./hooks/layout";

type AppRoute = {
  path: string;
  page: React.LazyExoticComponent<React.FC>;
  layout?: JSX.Element;
} & AppLayoutProps;

const pages: AppRoute[] = [
  {
    path: "*",
    page: lazy(() => import("./app/(authenticated)/not-found/page")),
    title: "404 - Page introuvable",
    control: {
      mode: "LOGGED_IN",
      role: "*",
      redirect: "/",
    },
  },
  {
    path: "/",
    page: lazy(() => import("./app/(authenticated)/page")),
    control: {
      mode: "LOGGED_IN",
      role: "*",
      redirect: "/",
    },
  },
  {
    path: "/login",
    page: lazy(() => import("./app/(public)/login/page")),
    title: "Pistache - connexion",
    control: {
      mode: "LOGGED_OUT",
      redirect: "/",
    },
  },
  {
    path: "/reset-password",
    page: lazy(() => import("./app/(public)/reset-password/page")),
    title: "Pistache - connexion",
    control: { mode: "LOGGED_OUT", redirect: "/" },
  },
  {
    path: "/oauth/:provider",
    page: lazy(() => import("./app/(public)/oauth/page")),
    control: { mode: "LOGGED_OUT", redirect: "/" },
  },
  // ADMIN
  {
    path: "/presentation",
    page: lazy(() => import("./app/(authenticated)/presentation/page")),
    control: {
      mode: "LOGGED_IN",
      role: "*",
      redirect: "/",
    },
    body: { maxWidth: "xl" },
    topBar: { hidden: true },
  },
  {
    path: "/accounts",
    page: lazy(() => import("./app/(authenticated)/(admin)/accounts/page")),
    title: "Pistache - comptes",
    control: {
      mode: "LOGGED_IN",
      role: ["admin", "cm"],
      redirect: "/",
    },
  },
  {
    path: "/calendar",
    page: lazy(() => import("./app/(authenticated)/(admin)/calendar/page")),
    title: "Pistache - calendrier",
    control: {
      mode: "LOGGED_IN",
      role: ["admin", "cm"],
      redirect: "/",
    },
  },
  {
    path: "/new-account",
    page: lazy(
      () => import("./app/(authenticated)/(admin)/accounts/new-account/page")
    ),
    title: "Pistache - nouveau compte",
    control: {
      mode: "LOGGED_IN",
      role: ["admin", "cm"],
      redirect: "/",
    },
  },
  {
    path: "/edit-account/:id",
    page: lazy(
      () => import("./app/(authenticated)/(admin)/accounts/new-account/page")
    ),
    title: "Pistache - compte client",
    control: {
      mode: "LOGGED_IN",
      role: ["admin", "cm"],
      redirect: "/",
    },
  },
  {
    path: "/users/:type?",
    page: lazy(() => import("./app/(authenticated)/(admin)/users/page")),
    title: "Pistache - utilisateurs",
    control: {
      mode: "LOGGED_IN",
      role: ["admin", "cm"],
      redirect: "/",
    },
  },
  {
    path: "/account/:id",
    page: lazy(
      () => import("./app/(authenticated)/(admin)/accounts/[account]/page")
    ),
    title: "Pistache - compte client",
    control: {
      mode: "LOGGED_IN",
      role: ["admin", "cm"],
      redirect: "/",
    },
  },
  {
    path: "/account/:id/:page/:type?",
    page: lazy(
      () => import("./app/(authenticated)/(admin)/accounts/[account]/page")
    ),
    title: "Pistache - compte client",
    control: {
      mode: "LOGGED_IN",
      role: ["admin", "cm"],
      redirect: "/",
    },
  },
  {
    path: "/:accountId/new-reporting",
    page: lazy(
      () =>
        import(
          "./app/(authenticated)/(admin)/accounts/[account]/reporting/new-reporting/page"
        )
    ),
    title: "Pistache - Nouveau compte rendu",
    control: {
      mode: "LOGGED_IN",
      role: ["admin", "cm"],
      redirect: "/",
    },
  },
  {
    path: "/:accountId/reporting/:id",
    page: lazy(
      () =>
        import(
          "./app/(authenticated)/(admin)/accounts/[account]/reporting/[id]/page"
        )
    ),
    title: "Pistache - reporting",
    control: {
      mode: "LOGGED_IN",
      role: ["admin", "cm"],
      redirect: "/",
    },
  },
  {
    path: "/:accountId/edit-reporting/:id",
    page: lazy(
      () =>
        import(
          "./app/(authenticated)/(admin)/accounts/[account]/reporting/new-reporting/page"
        )
    ),
    title: "Pistache - reporting",
    control: {
      mode: "LOGGED_IN",
      role: ["admin", "cm"],
      redirect: "/",
    },
  },
  {
    path: "/workspaces",
    page: lazy(() => import("./app/(authenticated)/(admin)/workspaces/page")),
    title: "Pistache - workspaces",
    control: {
      mode: "LOGGED_IN",
      role: ["admin", "cm"],
      redirect: "/",
    },
  },
  {
    path: "/workspaces/:id/:page",
    page: lazy(
      () => import("./app/(authenticated)/(admin)/workspaces/[workspace]/page")
    ),
    title: "Pistache - workspace",
    control: {
      mode: "LOGGED_IN",
      role: ["admin", "cm"],
      redirect: "/",
    },
  },
  // CUSTOMER
  {
    path: "/planning/:id?",
    page: lazy(() => import("./app/(authenticated)/(customer)/planning/page")),
    control: {
      mode: "LOGGED_IN",
      role: ["customer", "ad_manager"],
      redirect: "/",
    },
    title: "Pistache - Planning",
  },
  {
    path: "/leads",
    page: lazy(() => import("./app/(authenticated)/(customer)/leads/page")),
    control: {
      mode: "LOGGED_IN",
      role: ["customer"],
      redirect: "/",
    },
    title: "Pistache - Leads",
  },
  {
    path: "/gmb",
    page: lazy(() => import("./app/(authenticated)/(customer)/gmb/page")),
    control: {
      mode: "LOGGED_IN",
      role: ["customer"],
      redirect: "/",
    },
    title: "Pistache - Google my Business",
  },
  {
    path: "/gmb/reviews",
    page: lazy(
      () => import("./app/(authenticated)/(customer)/gmb/reviews/page")
    ),
    control: {
      mode: "LOGGED_IN",
      role: ["customer"],
      redirect: "/",
    },
    title: "Pistache - Google my Business",
  },
  // DEVELOPPER
  {
    path: "/documentation",
    page: lazy(
      () => import("./app/(authenticated)/(developper)/documentation/page")
    ),
    control: {
      mode: "LOGGED_IN",
      role: ["developper"],
      redirect: "/",
    },
    title: "Pistache - Developper",
    body: { maxWidth: false },
    topBar: { maxWidth: false },
  },
];

export const getPageMetaData = (pathName: string) => {
  return Object.values(navigations)
    .flatMap((v) => v)
    .find(({ href }) => href === pathName);
};

export default pages;
