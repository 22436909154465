import React, { useEffect, useCallback, useMemo, useState } from "react";
import { useStrapi } from "@/providers/StrapiProvider";
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { authState } from "@/store/auth";
import { useAuth } from "@/hooks/auth";
import { Drawer, LinearProgress } from "@mui/material";
import { notificationModal, notificationsSelector } from "@/store/notification";
import { ContainerProps, useColorMode } from "@repleek/mui";
import { AdminLayout, Sidebar } from "@/components";
import Menu from "@/components/layout/Menu";
import BreadCrumb from "@/components/layout/BreadCrumb";
import Notifications from "@/components/layout/Notifications";
import { PistacheLoader } from "@/components/layout/modals";
import SelectAccount from "@/components/features/auth/SelectAccount";
import LoginPage from "@/components/features/auth/LoginPage";
import { Outlet } from "react-router-dom";
import { useSelectAccount } from "@/hooks/layout";
import { User } from "@/interfaces/strapi/collections";

export type AppLayoutProps = {
  control: (
    | { mode: "LOGGED_OUT" | "PUBLIC" }
    | {
        mode: "LOGGED_IN";
        role: User["type"][] | "*";
      }
  ) & { redirect: string };
  title?: string;

  body?: Omit<ContainerProps, "children">;
  topBar?: Omit<ContainerProps, "children">;
};

const AppLayout: React.FC<AppLayoutProps> = (props) => {
  const { title = "Pistache Report", control, body, topBar } = props;

  const [strapi, { loading }] = useStrapi();
  const user = useRecoilValue(authState);
  const auth = useAuth(control);
  const canSelectAccount = useSelectAccount(user);
  const [notification, setNotification] = useRecoilState(notificationModal);
  const [sidebarDrawer, setSidebarDrawer] = useState(false);
  const refreshNotifications = useRecoilRefresher_UNSTABLE(
    notificationsSelector
  );

  const { mode: theme, setMode } = useColorMode();

  const userName = useMemo(
    () => ({
      name:
        [user?.firstName, user?.lastName].filter((v) => v).join(" ") ||
        user?.email,
      initial: [user?.firstName?.[0], user?.lastName?.[0]]
        .filter((v) => v)
        .join(""),
      src: user?.avatar?.url,
    }),
    [user]
  );

  const openNotification = useCallback(() => {
    refreshNotifications();
    setNotification(true);
  }, [refreshNotifications, setNotification]);

  const logout = useCallback(() => {
    user && strapi?.logout();
  }, [user, strapi]);

  const sidebar = useMemo(
    () => (
      <Sidebar
        user={{ hidden: !user }}
        avatar={{ initial: userName.initial, src: userName.src }}
        name={{ text: userName.name }}
        menu={{ children: user && <Menu type={user.type} /> }}
        logout={{ onClick: logout, hidden: !user }}
        login={{ hidden: !!user }}
        logoLigth={{ hidden: theme === "dark" }}
        logoDark={{ hidden: theme === "light" }}
      />
    ),
    [logout, theme, user, userName.initial, userName.name, userName.src]
  );

  const content = useMemo(() => {
    if (auth.loading)
      return <PistacheLoader css={{ height: "calc(100vh - 180px)" }} />;
    if (!auth.autorized) return <LoginPage />;
    return <Outlet />;
  }, [auth.autorized, auth.loading]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <>
      {loading && (
        <LinearProgress
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 200,
          }}
          color="secondary"
        />
      )}

      <AdminLayout
        body={{
          ...body,
          children: content,
        }}
        topBar={{ ...topBar }}
        breadcrumb={{ children: <BreadCrumb /> }}
        notification={{
          onClick: openNotification,
          hidden: !user?.type || !["admin", "cm"].includes(user.type),
        }}
        light={{ onClick: () => setMode("dark"), hidden: theme === "dark" }}
        dark={{ onClick: () => setMode("light"), hidden: theme === "light" }}
        sidebar={{
          hidden: !user,
          children: sidebar,
        }}
        menu={{
          onClick: () => setSidebarDrawer(true),
          hidden: !user,
        }}
        userAccountSelect={{
          hidden: !canSelectAccount,
          children: <SelectAccount />,
        }}
      />

      <Drawer
        open={notification}
        anchor="right"
        onClose={() => setNotification(false)}
        PaperProps={{
          sx: {
            height: "calc(100% - 16px)",
            top: "8px",
            right: "12px",
            borderRadius: "18px",
          },
        }}
      >
        <Notifications />
      </Drawer>

      <Drawer
        open={!!user && sidebarDrawer}
        anchor="right"
        onClose={() => setSidebarDrawer(false)}
        PaperProps={{
          sx: {
            height: "calc(100% - 16px)",
            top: "8px",
            right: "12px",
            borderRadius: "18px",
          },
        }}
      >
        {sidebar}
      </Drawer>
    </>
  );
};

export default AppLayout;
