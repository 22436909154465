/** Build with repleek */
// #region Import
import React from "react";

import {
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  Fragment,
  FragmentProps,
  TextField,
  Typography,
  TypographyProps,
} from "@repleek/mui";
// #endregion

// #region Type Definition
type ReviewFormProps = Partial<BoxProps> & {
  name: TypographyProps & {},
  date: TypographyProps & {},
  logo: Partial<AvatarProps>,
  stars: Partial<FragmentProps>,
  body: Partial<BoxProps>,
};
// #endregion

// #region ReviewFormComponent
const ReviewForm: React.FC<ReviewFormProps> = (props) => {
  const { name, date, logo, stars, body, ...rootProps } = props;

  return (
    <Box tag={"div"} {...rootProps}>
      <Box
        tag={"div"}
        css={{ gap: "16px", display: "flex", alignItems: "center" }}
      >
        <Avatar
          variant={"circular"}
          component={"div"}
          css={{ width: "48px", height: "48px" }}
          {...logo}
        />
        <Box tag={"div"} css={{ flexGrow: "1" }}>
          <Typography
            variant={"h4"}
            text={"Mon titre ici"}
            gutterBottom={false}
            noWrap={false}
            css={{ fontSize: "18px" }}
            {...name}
          />
          <Box
            tag={"div"}
            css={{
              gap: "8px",
              display: "flex",
              alignItems: "center",
              svg: { color: "rgb(249, 125, 241)", fontSize: "18px" },
            }}
          >
            <Typography
              variant={"body1"}
              text={"Mon titre ici"}
              gutterBottom={false}
              noWrap={false}
              css={{ fontSize: "18px" }}
              {...date}
            />
            <Fragment {...stars} />
          </Box>
        </Box>
      </Box>
      <Box
        tag={"div"}
        css={{
          padding: "16px",
          maxHeight: "280px",
          backgroundColor: "rgba(171, 171, 177, 0.12)",
          overflowY: "auto",
          borderRadius: "8px",
          marginTop: "8px",
          marginBottom: "8px",
        }}
        {...body}
      />
      <TextField
        inputName={"reply"}
        label={"Réponse en mode publique"}
        variant={"outlined"}
        color={"primary"}
        type={"text"}
        aria-label={"Input Base Mui"}
        autoFocus={false}
        disabled={false}
        fullWidth={true}
        maxRows={"8"}
        minRows={"3"}
        multiline={true}
        readOnly={false}
        required={true}
        size={"small"}
        css={{ marginTop: "8px" }}
      />
    </Box>
  );
};

export default ReviewForm;
// #endregion
