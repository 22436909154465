/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  BoxProps,
  Button,
  ButtonIcon,
  ButtonIconProps,
  ButtonProps,
  Container,
  ContainerProps,
  Icon,
} from "@repleek/mui";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoNotificationsOutline } from "react-icons/io5";
import { CiBrightnessUp, CiDark } from "react-icons/ci";
// #endregion

// #region Type Definition
type AdminLayoutProps = Partial<BoxProps> & {
  body: Partial<ContainerProps>,
  topBar: Partial<ContainerProps>,
  breadcrumb: Partial<BoxProps>,
  sidebar: Partial<BoxProps>,
  menu: Partial<ButtonIconProps>,
  userAccountSelect: Partial<BoxProps>,
  notification: Partial<ButtonProps>,
  light: Partial<ButtonProps>,
  dark: Partial<ButtonProps>,
};
// #endregion

// #region AdminLayoutComponent
const AdminLayout: React.FC<AdminLayoutProps> = (props) => {
  const {
    body,
    topBar,
    breadcrumb,
    sidebar,
    menu,
    userAccountSelect,
    notification,
    light,
    dark,
    ...rootProps
  } = props;

  return (
    <Box
      tag={"div"}
      css={{
        height: "100vh",
        backgroundColor: "rgba(246, 245, 245, 1)",
        overflowY: "hidden",
        display: "flex",
        ".mui-dark &": { backgroundColor: "rgba(21, 20, 25, 1)" },
      }}
      {...rootProps}
    >
      <Box
        tag={"div"}
        css={{
          padding: "12px",
          height: "100%",
          "@media (max-width: 980px)": { display: "none" },
        }}
        {...sidebar}
      />
      <Box tag={"div"} css={{ overflowY: "auto", flexGrow: "1" }}>
        <Container
          maxWidth={"lg"}
          disableGutters={false}
          fixed={false}
          css={{
            backgroundColor: "rgba(246, 245, 245, 1)",
            zIndex: "100",
            paddingTop: "24px",
            paddingBottom: "8px",
            position: "sticky",
            top: "0px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            ".mui-dark &": { backgroundColor: "rgba(21, 20, 25, 1)" },
          }}
          {...topBar}
        >
          <Box tag={"div"} css={{ flexGrow: "1" }} {...breadcrumb} />
          <Box
            tag={"div"}
            css={{
              gap: "8px",
              display: "flex",
              alignItems: "center",
              "@media (max-width: 980px)": {
                display: "flex",
                flexWrap: "nowrap",
                alignItems: "center",
              },
            }}
          >
            <Box tag={"div"} {...userAccountSelect} />
            <Button
              type={"button"}
              disabled={false}
              disableElevation={false}
              disableFocusRipple={false}
              disableRipple={false}
              fullWidth={false}
              variant={"outlined"}
              color={"secondary"}
              iconStart={true}
              iconEnd={false}
              className={"icon-btn"}
              IconStart={
                <Icon
                  color={"inherit"}
                  fontSize={"inherit"}
                  icon={CiBrightnessUp}
                />
              }
              {...light}
            />
            <Button
              type={"button"}
              disabled={false}
              disableElevation={false}
              disableFocusRipple={false}
              disableRipple={false}
              fullWidth={false}
              variant={"outlined"}
              color={"secondary"}
              iconStart={true}
              iconEnd={false}
              className={"icon-btn"}
              IconStart={
                <Icon color={"inherit"} fontSize={"inherit"} icon={CiDark} />
              }
              {...dark}
            />
            <Button
              type={"button"}
              disabled={false}
              disableElevation={false}
              disableFocusRipple={false}
              disableRipple={false}
              fullWidth={false}
              variant={"contained"}
              color={"secondary"}
              iconStart={true}
              iconEnd={false}
              className={"icon-btn"}
              IconStart={
                <Icon
                  color={"inherit"}
                  fontSize={"inherit"}
                  icon={IoNotificationsOutline}
                />
              }
              {...notification}
            />
            <Box
              tag={"div"}
              css={{
                display: "none",
                "@media (max-width: 980px)": { display: "inline-block" },
              }}
            >
              <ButtonIcon
                type={"button"}
                disabled={false}
                disableFocusRipple={false}
                disableRipple={false}
                color={"secondary"}
                css={{ marginLeft: "12px" }}
                Icon={
                  <Icon
                    color={"inherit"}
                    fontSize={"inherit"}
                    icon={RxHamburgerMenu}
                  />
                }
                {...menu}
              />
            </Box>
          </Box>
        </Container>
        <Container
          maxWidth={"lg"}
          disableGutters={false}
          fixed={false}
          css={{ marginBottom: "16px" }}
          {...body}
        />
      </Box>
    </Box>
  );
};

export default AdminLayout;
// #endregion
