import { atom } from "recoil";
import { LayoutDefinition } from "@/interfaces";
import { IconType } from "react-icons";

export type MenuItem = {
  label: string;
  href: string;
  icon?: IconType;
  active?: boolean;
  children?: Omit<MenuItem, "children">[];
};

export type SubMenu = "accounts" | "workspaces";

export const layoutState = atom<LayoutDefinition>({
  key: "layout-state",
  default: {},
});

export const subMenuState = atom<Partial<Record<SubMenu, MenuItem[]>>>({
  key: "sub-menu-state",
  default: {},
});
