import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { API_URL } from "@/constant";
import Strapi from "@/modules/strapi-sdk";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { authState } from "@/store/auth";
import { strapiLoadingAtom } from "@/store/strapi";

const StrapiContext = createContext<Strapi | null>(null);

export const strapiInstance = new Strapi({
  url: API_URL,
  store: {
    key: "strapi_jwt",
    useLocalStorage: true,
    cookieOptions: { path: "/" },
  },
  axiosOptions: {
    headers: {
      "ngrok-skip-browser-warning": "69420",
    },
  },
});

export const StrapiProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [strapi, setStrapi] = useState<Strapi | null>(null);
  const setUser = useSetRecoilState(authState);

  useEffect(() => {
    if (!strapi) {
      strapiInstance.options.events.onAuthChange = (user) => setUser(user);
      setStrapi(strapiInstance);
    }
  }, [setUser, strapi]);

  return (
    <StrapiContext.Provider value={strapi}>{children}</StrapiContext.Provider>
  );
};

export const useStrapiInstance = () => useContext(StrapiContext);

export const useStrapiLoading = () => useRecoilValue(strapiLoadingAtom);

export const useStrapi = () => {
  const strapi = useContext(StrapiContext);
  const loading = useRecoilValue(strapiLoadingAtom);

  return [strapi, { loading }] as const;
};
