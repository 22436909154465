/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Icon,
  IconProps,
  InputBase,
  InputBaseProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";
import { IoClose, IoFilter, IoSearchOutline } from "react-icons/io5";
import { RiRefreshLine } from "react-icons/ri";
import { GoArrowLeft, GoArrowRight } from "react-icons/go";
import { IoMdAdd } from "react-icons/io";
import { LuCalendarDays } from "react-icons/lu";
import { MdViewWeek } from "react-icons/md";
// #endregion

// #region Type Definition
type CalendarHeaderProps = Partial<BoxProps> & {
  filterBtn: Partial<ButtonProps>,
  searchField: Partial<InputBaseProps>,
  clearSearch: Partial<IconProps>,
  refreshBtn: Partial<ButtonProps>,
  prevBtn: Partial<ButtonProps>,
  nextBtn: Partial<ButtonProps>,
  nowBtn: ButtonProps & {},
  newBtn: Partial<ButtonProps>,
  calendarBtn: Partial<ButtonProps>,
  weekBtn: Partial<ButtonProps>,
  date: TypographyProps & {},
};
// #endregion

// #region CalendarHeaderComponent
const CalendarHeader: React.FC<CalendarHeaderProps> = (props) => {
  const {
    filterBtn,
    searchField,
    clearSearch,
    refreshBtn,
    prevBtn,
    nextBtn,
    nowBtn,
    newBtn,
    calendarBtn,
    weekBtn,
    date,
    ...rootProps
  } = props;

  return (
    <Box
      tag={"div"}
      css={{
        width: "100%",
        paddingTop: "8px",
        paddingBottom: "8px",
        gap: "12px",
        marginTop: "-14px",
        marginBottom: "-14px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      {...rootProps}
    >
      <Box
        tag={"div"}
        css={{ gap: "4px", display: "flex", alignItems: "center" }}
      >
        <Button
          type={"button"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          size={"small"}
          variant={"text"}
          color={"secondary"}
          iconStart={true}
          iconEnd={false}
          className={"icon-btn filter-btn"}
          css={{ paddingLeft: "12px", paddingRight: "12px" }}
          IconStart={
            <Icon color={"inherit"} fontSize={"inherit"} icon={GoArrowLeft} />
          }
          {...prevBtn}
        />
        <Button
          text={"Aujourd'hui"}
          type={"button"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          size={"small"}
          variant={"outlined"}
          color={"secondary"}
          iconStart={false}
          iconEnd={false}
          css={{ paddingLeft: "12px", paddingRight: "12px" }}
          {...nowBtn}
        />
        <Button
          type={"button"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          size={"small"}
          variant={"text"}
          color={"secondary"}
          iconStart={true}
          iconEnd={false}
          className={"icon-btn filter-btn"}
          css={{ paddingLeft: "12px", paddingRight: "12px" }}
          IconStart={
            <Icon color={"inherit"} fontSize={"inherit"} icon={GoArrowRight} />
          }
          {...nextBtn}
        />
        <Button
          type={"button"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          size={"small"}
          variant={"text"}
          color={"secondary"}
          iconStart={true}
          iconEnd={false}
          className={"icon-btn filter-btn"}
          css={{ paddingLeft: "12px", paddingRight: "12px" }}
          IconStart={
            <Icon
              color={"inherit"}
              fontSize={"inherit"}
              icon={LuCalendarDays}
            />
          }
          {...calendarBtn}
        />
        <Button
          type={"button"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          size={"small"}
          variant={"text"}
          color={"secondary"}
          iconStart={true}
          iconEnd={false}
          className={"icon-btn filter-btn"}
          css={{ paddingLeft: "12px", paddingRight: "12px" }}
          IconStart={
            <Icon color={"inherit"} fontSize={"inherit"} icon={MdViewWeek} />
          }
          {...weekBtn}
        />
      </Box>
      <Typography
        variant={"h3"}
        text={"Mon titre ici"}
        gutterBottom={false}
        noWrap={false}
        css={{ fontSize: "16px", textTransform: "uppercase" }}
        {...date}
      />
      <Box
        tag={"div"}
        css={{
          gap: "8px",
          paddingTop: "8px",
          paddingBottom: "8px",
          display: "inline-flex",
        }}
      >
        <Box
          tag={"div"}
          css={{
            minWidth: "248px",
            boxShadow:
              "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
            borderRadius: "14px",
            paddingLeft: "8px",
            paddingRight: "8px",
            display: "inline-flex",
            alignItems: "center",
            ".mui-dark &": {
              boxShadow:
                "rgba(252, 252, 252, 0.02) 0px 1px 3px 0px  , rgba(253, 253, 253, 0.15) 0px 0px 0px 1px ",
            },
          }}
        >
          <Icon color={"inherit"} fontSize={"inherit"} icon={IoSearchOutline} />
          <InputBase
            inputName={"Input name"}
            color={"primary"}
            type={"text"}
            aria-label={"Input Base Mui"}
            placeholder={"Recherche ..."}
            autoFocus={true}
            disabled={false}
            fullWidth={true}
            maxRows={1}
            minRows={1}
            rows={1}
            multiline={false}
            readOnly={false}
            required={false}
            size={"medium"}
            css={{ paddingLeft: "8px", flexGrow: "1" }}
            {...searchField}
          />
          <Icon
            color={"inherit"}
            fontSize={"inherit"}
            icon={IoClose}
            css={{ cursor: "pointer" }}
            {...clearSearch}
          />
        </Box>
        <Button
          type={"button"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          size={"small"}
          variant={"outlined"}
          color={"secondary"}
          iconStart={true}
          iconEnd={false}
          className={"icon-btn filter-btn"}
          css={{ paddingLeft: "12px", paddingRight: "12px" }}
          IconStart={
            <Icon color={"inherit"} fontSize={"inherit"} icon={IoFilter} />
          }
          {...filterBtn}
        />
        <Button
          type={"button"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          size={"small"}
          variant={"outlined"}
          color={"secondary"}
          iconStart={true}
          iconEnd={false}
          className={"icon-btn"}
          css={{ padding: "0px", paddingLeft: "12px", paddingRight: "12px" }}
          IconStart={
            <Icon color={"inherit"} fontSize={"inherit"} icon={RiRefreshLine} />
          }
          {...refreshBtn}
        />
        <Button
          type={"button"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          size={"small"}
          variant={"outlined"}
          color={"secondary"}
          iconStart={true}
          iconEnd={false}
          className={"icon-btn"}
          css={{ padding: "0px", paddingLeft: "12px", paddingRight: "12px" }}
          IconStart={
            <Icon color={"inherit"} fontSize={"inherit"} icon={IoMdAdd} />
          }
          {...newBtn}
        />
      </Box>
    </Box>
  );
};

export default CalendarHeader;
// #endregion
