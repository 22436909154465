/** Build with repleek */
// #region Import
import React from "react";

import {
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  Container,
  ContainerProps,
  Icon,
  IconProps,
  Image,
  ImageProps,
  Paper,
  Typography,
  TypographyProps,
} from "@repleek/mui";
import { LuTrash2 } from "react-icons/lu";
// #endregion

// #region Type Definition
type WkSsoCardProps = Partial<ContainerProps> & {
  name: TypographyProps & {},
  logo: Partial<ImageProps>,
  syncBtn: Partial<BoxProps>,
  description: TypographyProps & {},
  accountSynced: Partial<BoxProps>,
  accountAvatar: Partial<AvatarProps>,
  accountName: TypographyProps & {},
  unlinkBtn: Partial<IconProps>,
};
// #endregion

// #region WkSsoCardComponent
const WkSsoCard: React.FC<WkSsoCardProps> = (props) => {
  const {
    name,
    logo,
    syncBtn,
    description,
    accountSynced,
    accountAvatar,
    accountName,
    unlinkBtn,
    ...rootProps
  } = props;

  return (
    <Container
      maxWidth={"md"}
      disableGutters={false}
      fixed={false}
      css={{ marginTop: "16px", marginBottom: "16px" }}
      {...rootProps}
    >
      <Paper
        square={true}
        variant={"elevation"}
        elevation={0}
        css={{
          padding: "24px",
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px , rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
          gap: "12px",
          borderRadius: "22px",
          display: "flex",
          ".mui-dark &": { backgroundColor: "rgba(39, 37, 45, 1)" },
        }}
      >
        <Box tag={"div"} css={{ gap: "8px", flexGrow: "1", display: "flex" }}>
          <Image
            alt={"Image description for better SEO"}
            src={"/images/logo-google.svg"}
            dynamic={true}
            css={{ width: "48px", height: "48px" }}
            {...logo}
          />
          <Box
            tag={"div"}
            css={{ gap: "8px", flexGrow: "1", paddingLeft: "8px" }}
          >
            <Typography
              variant={"h3"}
              text={"Mon titre ici"}
              gutterBottom={false}
              noWrap={false}
              css={{ maxWidth: "480px", fontSize: "20px", marginBottom: "8px" }}
              {...name}
            />
            <Typography
              variant={"body1"}
              text={"Mon titre ici"}
              gutterBottom={false}
              noWrap={false}
              {...description}
            />
          </Box>
        </Box>
        <Box
          tag={"div"}
          css={{
            minWidth: "230px",
            paddingLeft: "16px",
            borderLeftWidth: "1px",
            borderLeftStyle: "solid",
            borderLeftColor: "rgb(212, 212, 212)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box tag={"div"} css={{ flexGrow: "1" }} {...syncBtn} />
          <Box
            tag={"div"}
            css={{
              boxShadow:
                "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
              gap: "8px",
              borderRadius: "14px",
              paddingLeft: "8px",
              paddingRight: "8px",
              paddingTop: "4px",
              paddingBottom: "4px",
              display: "flex",
              alignItems: "center",
            }}
            {...accountSynced}
          >
            <Avatar
              variant={"circular"}
              component={"div"}
              css={{
                color: "rgba(255,255,255, 1)",
                background:
                  "linear-gradient(90deg, rgb(255, 175, 189) 0%, rgb(255, 195, 160) 100%)",
              }}
              {...accountAvatar}
            />
            <Typography
              variant={"h3"}
              text={"Mon titre ici"}
              gutterBottom={false}
              noWrap={false}
              css={{ fontSize: "14px", flexGrow: "1" }}
              {...accountName}
            />
            <Icon
              color={"inherit"}
              fontSize={"inherit"}
              icon={LuTrash2}
              css={{ padding: "2px", fontSize: "24px", cursor: "pointer" }}
              {...unlinkBtn}
            />
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default WkSsoCard;
// #endregion
