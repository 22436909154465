/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Container,
  ContainerProps,
  Form,
  FormProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";
// #endregion

// #region Type Definition
type GmbConfigurationFormProps = Partial<ContainerProps> & {
  saveBtn: ButtonProps & {},
  form: Partial<FormProps>,
  name: TypographyProps & {},
  description: TypographyProps & {},
  fields: Partial<BoxProps>,
};
// #endregion

// #region GmbConfigurationFormComponent
const GmbConfigurationForm: React.FC<GmbConfigurationFormProps> = (props) => {
  const { saveBtn, form, name, description, fields, ...rootProps } = props;

  return (
    <Container
      maxWidth={"sm"}
      disableGutters={false}
      fixed={false}
      css={{}}
      {...rootProps}
    >
      <Form mode={"onSubmit"} css={{ paddingTop: "16px" }} {...form}>
        <Typography
          variant={"h3"}
          text={"Personnalisation des Réponses IA"}
          gutterBottom={false}
          noWrap={false}
          css={{ fontSize: "18px", marginBottom: "8px", marginTop: "16px" }}
          {...name}
        />
        <Typography
          variant={"body1"}
          text={
            "<i>Paramétrez l'IA pour générer des réponses automatiques aux avis clients</i>"
          }
          gutterBottom={false}
          noWrap={false}
          css={{ fontSize: "14px" }}
          {...description}
        />
        <Box tag={"div"} css={{ marginTop: "8px" }} {...fields} />
        <Box
          tag={"div"}
          className={"bg-white"}
          css={{
            zIndex: "10",
            textAlign: "right",
            position: "sticky",
            bottom: "-16px",
            paddingTop: "8px",
            paddingBottom: "16px",
          }}
        >
          <Button
            text={"Enregistrer"}
            type={"submit"}
            disabled={false}
            disableElevation={false}
            disableFocusRipple={false}
            disableRipple={false}
            fullWidth={false}
            variant={"contained"}
            iconStart={false}
            iconEnd={false}
            css={{ marginTop: "18px" }}
            {...saveBtn}
          />
        </Box>
      </Form>
    </Container>
  );
};

export default GmbConfigurationForm;
// #endregion
