import { JWTSession, Provider } from "@/interfaces";
import { StrapiUser } from "@/modules/strapi-sdk";
import { Account } from "@/interfaces/strapi/collections";
import { strapiInstance } from "@/providers/StrapiProvider";
import { DefaultValue, atom, atomFamily, selector } from "recoil";
import { accountSelectorPopulate } from "./accounts";

export const authState = atom<undefined | StrapiUser>({
  key: "auth-state",
  default: undefined,
});

export const socialsAuthState = atomFamily<JWTSession | undefined, Provider>({
  key: "socials-auth-state",
  default: undefined,
  effects: (provider) => [
    ({ setSelf, onSet }) => {
      if (provider) {
        const key = `${provider}_jwt`;
        const savedValue = localStorage.getItem(key);
        if (savedValue) {
          try {
            const data =
              savedValue !== "undefined" ? JSON.parse(savedValue) : undefined;
            setSelf(data);
          } catch (error) {
            console.log("error: ", error);
            setSelf(undefined);
          }
        }

        onSet((newValue) => {
          if (newValue instanceof DefaultValue || !newValue) {
            localStorage.removeItem(key);
          } else {
            localStorage.setItem(key, JSON.stringify(newValue));
          }
        });
      }
    },
  ],
});

export const accountsUserState = selector({
  key: "account-user-state",
  get: async ({ get }) => {
    const user = get(authState);
    if (!user?.id) return undefined;

    return (
      (
        await strapiInstance.find("accounts", {
          filters: {
            users_access: {
              $in: [user.id],
            },
          },
          populate: accountSelectorPopulate,
        })
      )?.data || []
    );
  },
});

export const accountSelected = atom<Account | undefined>({
  key: "user-account-selected",
  default: undefined,
});
