import { Box, Button } from "@mui/material";
import React, { Suspense } from "react";
import { NavLink } from "react-router-dom";
import { User } from "@/interfaces/strapi/collections";
import { useMenu } from "@/hooks/layout";

type Props = { type: User["type"] };

const Menu: React.FC<Props> = ({ type }) => {
  const menu = useMenu(type);

  if (!menu.length) return null;

  return (
    <>
      {menu.map(({ href, label, icon: Icon, active, children }, index) => (
        <>
          <NavLink key={`${href}-${index}`} to={href} end>
            {({ isActive }) => (
              <Button
                startIcon={Icon && <Icon style={{ marginRight: "8px" }} />}
                sx={{
                  justifyContent: "start",
                  borderColor: "transparent",
                  padding: "8px 16px",
                  borderRadius: "10px",
                  boxShadow: "none",
                  ...((isActive || active) && {
                    border: "1px solid",
                  }),
                  mb: 0.5,
                  ":hover": {
                    boxShadow: "none",
                  },
                }}
                fullWidth
                color="secondary"
                size="small"
                variant="outlined"
              >
                {label}
              </Button>
            )}
          </NavLink>
          {children && (
            <Box sx={{ pl: "16px" }} key="sub-menu">
              {children?.map(({ href, label, icon: Icon, active }, index) => (
                <NavLink key={`sub-menu-${href}-${index}`} to={href} end>
                  {({ isActive }) => (
                    <Button
                      startIcon={
                        Icon && <Icon style={{ marginRight: "8px" }} />
                      }
                      sx={{
                        justifyContent: "start",
                        borderColor: "transparent",
                        padding: "6px 14px",
                        borderRadius: "10px",
                        fontSize: "11px",
                        mb: 0.2,
                        ":hover": {
                          boxShadow: "none",
                        },
                      }}
                      fullWidth
                      color="secondary"
                      size="small"
                      variant={!isActive ? "outlined" : "contained"}
                    >
                      {label}
                    </Button>
                  )}
                </NavLink>
              ))}
            </Box>
          )}
        </>
      ))}
    </>
  );
};

const SuspendedMenu: React.FC<Props> = (props) => {
  return (
    <Suspense>
      <Menu {...props} />
    </Suspense>
  );
};

export default SuspendedMenu;
