/** Build with repleek */
// #region Import
import React from "react";

import {
  Avatar,
  AvatarProps,
  Box,
  Button,
  ButtonProps,
  Container,
  ContainerProps,
  Paper,
  Typography,
  TypographyProps,
} from "@repleek/mui";
// #endregion

// #region Type Definition
type WorkspaceDetailProps = Partial<ContainerProps> & {
  editBtn: ButtonProps & {},
  name: TypographyProps & {},
  informations: ButtonProps & {},
  members: ButtonProps & {},
  accounts: ButtonProps & {},
  avatar: Partial<AvatarProps>,
  users: ButtonProps & {},
  profiles: ButtonProps & {},
};
// #endregion

// #region WorkspaceDetailComponent
const WorkspaceDetail: React.FC<WorkspaceDetailProps> = (props) => {
  const {
    editBtn,
    name,
    informations,
    members,
    accounts,
    avatar,
    users,
    profiles,
    ...rootProps
  } = props;

  return (
    <Container
      maxWidth={"lg"}
      disableGutters={false}
      fixed={false}
      css={{ marginTop: "16px", marginBottom: "16px" }}
      {...rootProps}
    >
      <Paper
        square={true}
        variant={"elevation"}
        elevation={0}
        css={{
          padding: "24px",
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
          gap: "12px",
          borderRadius: "22px",
          display: "flex",
          ".mui-dark &": { backgroundColor: "rgba(39, 37, 45, 1)" },
        }}
      >
        <Avatar
          variant={"circular"}
          component={"div"}
          css={{
            width: "110px",
            height: "110px",
            fontSize: "48px",
            boxShadow: "rgba(119, 128, 128, 0.3) 0px 0px 0px 4px ",
            background:
              "linear-gradient(90deg, rgb(255, 175, 189) 0%, rgb(255, 195, 160) 100%)",
          }}
          {...avatar}
        />
        <Box
          tag={"div"}
          css={{
            flexGrow: "1",
            paddingLeft: "16px",
            borderLeftWidth: "1px",
            borderLeftStyle: "solid",
            borderLeftColor: "rgb(212, 212, 212)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box tag={"div"} css={{ flexGrow: "1" }}>
            <Box
              tag={"div"}
              css={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography
                variant={"h3"}
                text={"Mon titre ici"}
                gutterBottom={false}
                noWrap={false}
                css={{
                  maxWidth: "480px",
                  fontSize: "26px",
                  marginBottom: "8px",
                  flexGrow: "1",
                }}
                {...name}
              />
              <Button
                text={"Modifier"}
                type={"button"}
                disabled={false}
                disableElevation={false}
                disableFocusRipple={false}
                disableRipple={false}
                fullWidth={false}
                size={"small"}
                variant={"contained"}
                iconStart={false}
                iconEnd={false}
                css={{
                  paddingLeft: "12px",
                  paddingRight: "12px",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                }}
                {...editBtn}
              />
            </Box>
          </Box>
          <Box
            tag={"div"}
            css={{
              marginTop: "8px",
              ".action-tab": {
                boxShadow: "rgba(0, 0, 0, 0.06) 0px 2px 4px 0px ",
                borderStyle: "none",
                marginRight: "4px",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
                paddingBottom: "8px",
              },
              ".mui-dark &": { backgroundColor: "rgba(39, 37, 45, 1)" },
            }}
          >
            <Button
              text={"Info"}
              type={"button"}
              disabled={false}
              disableElevation={false}
              disableFocusRipple={false}
              disableRipple={false}
              fullWidth={false}
              iconStart={false}
              iconEnd={false}
              className={"action-tab"}
              {...informations}
            />
            <Button
              text={"Profiles"}
              type={"button"}
              disabled={false}
              disableElevation={false}
              disableFocusRipple={false}
              disableRipple={false}
              fullWidth={false}
              iconStart={false}
              iconEnd={false}
              className={"action-tab"}
              {...profiles}
            />
            <Button
              text={"membres"}
              type={"button"}
              disabled={false}
              disableElevation={false}
              disableFocusRipple={false}
              disableRipple={false}
              fullWidth={false}
              iconStart={false}
              iconEnd={false}
              className={"action-tab"}
              {...members}
            />
            <Button
              text={"clients"}
              type={"button"}
              disabled={false}
              disableElevation={false}
              disableFocusRipple={false}
              disableRipple={false}
              fullWidth={false}
              iconStart={false}
              iconEnd={false}
              className={"action-tab"}
              {...users}
            />
            <Button
              text={"comptes"}
              type={"button"}
              disabled={false}
              disableElevation={false}
              disableFocusRipple={false}
              disableRipple={false}
              fullWidth={false}
              iconStart={false}
              iconEnd={false}
              className={"action-tab"}
              {...accounts}
            />
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default WorkspaceDetail;
// #endregion
