/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  BoxProps,
  Container,
  ContainerProps,
  Divider,
  Typography,
  TypographyProps,
} from "@repleek/mui";
// #endregion

// #region Type Definition
type ReviewContainerProps = Partial<ContainerProps> & {
  list: Partial<BoxProps>,
  ratingChart: Partial<BoxProps>,
  reviewCount: TypographyProps & {},
  rating: TypographyProps & {},
  ratingBox: Partial<BoxProps>,
  pagination: Partial<BoxProps>,
};
// #endregion

// #region ReviewContainerComponent
const ReviewContainer: React.FC<ReviewContainerProps> = (props) => {
  const {
    list,
    ratingChart,
    reviewCount,
    rating,
    ratingBox,
    pagination,
    ...rootProps
  } = props;

  return (
    <Container
      maxWidth={"md"}
      disableGutters={false}
      fixed={false}
      {...rootProps}
    >
      <Box
        tag={"div"}
        className={"bg-white"}
        css={{ zIndex: "10", position: "sticky", top: "-16px" }}
      >
        <Box tag={"div"} />
        <Box tag={"div"} css={{ display: "flex", alignItems: "center" }}>
          <Box tag={"div"} css={{ padding: "8px", flexGrow: "1" }}>
            <Typography
              variant={"h3"}
              text={"Nombre d'avis"}
              gutterBottom={false}
              noWrap={false}
              css={{ fontSize: "18px" }}
            />
            <Typography
              variant={"h1"}
              text={"10.0k"}
              gutterBottom={false}
              noWrap={false}
              css={{ fontSize: "38px", marginTop: "16px" }}
              {...reviewCount}
            />
          </Box>
          <Divider
            chip={false}
            flexItem={false}
            light={false}
            orientation={"vertical"}
            textAlign={"right"}
            variant={"fullWidth"}
            css={{ height: "80px", marginRight: "16px" }}
          />
          <Box
            tag={"div"}
            css={{ padding: "8px", height: "100%", flexGrow: "1" }}
          >
            <Typography
              variant={"h3"}
              text={"Note moyenne"}
              gutterBottom={false}
              noWrap={false}
              css={{ fontSize: "18px" }}
            />
            <Box
              tag={"div"}
              css={{ gap: "8px", display: "flex", alignItems: "baseline" }}
            >
              <Typography
                variant={"h1"}
                text={"4.0"}
                gutterBottom={false}
                noWrap={false}
                css={{ fontSize: "38px", marginTop: "16px" }}
                {...rating}
              />
              <Box tag={"div"} {...ratingBox} />
            </Box>
          </Box>
          <Divider
            chip={false}
            flexItem={false}
            light={false}
            orientation={"vertical"}
            textAlign={"center"}
            variant={"fullWidth"}
            css={{ height: "80px" }}
          />
          <Box tag={"div"} css={{ width: "360px" }} {...ratingChart} />
        </Box>
        <Divider
          chip={false}
          flexItem={false}
          light={false}
          orientation={"horizontal"}
          textAlign={"center"}
          variant={"fullWidth"}
        />
      </Box>
      <Box tag={"div"} {...list} />
      <Box
        tag={"div"}
        className={"bg-white"}
        css={{
          position: "sticky",
          bottom: "-16px",
          paddingLeft: "8px",
          paddingRight: "8px",
          paddingTop: "0px",
          paddingBottom: "8px",
        }}
        {...pagination}
      />
    </Container>
  );
};

export default ReviewContainer;
// #endregion
